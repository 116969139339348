<template>
  <div>
    <div v-show="loading" class="loading-screen">
      <img src="./assets/logo.svg" class="loadlogo" alt="Loading..." />
      Loading...
    </div>
    <div v-show="!loading" class="yehcontainer">
      <div class="navcontainer">
        <div class="side-nav">
          <div class="logo"><a href="#home"></a></div>
        </div>
        <nav class="navbar">
          <a href="#uiux" @click="navigate('UIUX')" :class="{ active: currentSection === 'UIUX' }">
            UI/UX
            <div class="chevron" :class="{ active: currentSection === 'UIUX' }"></div>
          </a>
          <a href="#frontend" @click="navigate('Frontend')" :class="{ active: currentSection === 'Frontend' }">
            Frontend
            <div class="chevron" :class="{ active: currentSection === 'Frontend' }"></div>
          </a>
          <a href="#visual" @click="navigate('Visual')" :class="{ active: currentSection === 'Visual' }">
            Visual
            <div class="chevron" :class="{ active: currentSection === 'Visual' }"></div>
          </a>
        </nav>
        <div class="side-nav">
          <div class="menuitem">
            <a href="#about" @click.prevent="scrollToSection('about')">About</a>
          </div>
          <div class="menuitem">
            <a href="#contact" @click.prevent="scrollToSection('contact')">Contact</a>
          </div>
        </div>
      </div>

      <!-- Hero Section -->
      <div id="home" class="hero-section">
        <div class="Hiremath">
          <h1>Chidanand Hiremath</h1>
          <h2>Polymath</h2>
        </div>
        <div class="hero-intro">
          A master of many crafts, Chidanand Hiremath weaves together diverse
          talents to create extraordinary experiences.<br />His work transcends
          boundaries, leaving a lasting impression of brilliance and innovation.
        </div>
      </div>

      <div id="uiux" v-show="currentSection === 'UIUX'" class="section sticky-section">
        <div class="blaze-slider" ref="uiuxSlider">
          <div class="blaze-container slider-container">
            <div class="slider-title">UI/UX Projects</div>
            <div class="blaze-track">
              <div v-for="project in uiuxProjects" :key="project.id" :class="[
                'blaze-slide',
                'card',
                {
                  'fade-in': !loading,
                  clicked: selectedProjectId === project.id,
                },
              ]" @click="showDetails(project.id, 'UIUX')">
                <img :src="getImagePath(project.id)" :alt="project.name" />
                <div class="card-content">
                  <h3>{{ project.title }}</h3>
                  <div class="card-tags">
                    <div class="tag">{{ project.author }}</div>
                    <div class="tag">{{ project.date }}</div>
                  </div>
                </div>
                <div class="see-more">
                  <div class="chevron"></div>
                </div>
              </div>
            </div>
            <!-- <div class="fade-left"></div>
            <div class="fade-right"></div> -->
            <button class="blaze-prev arrow arrow-left" @click="prev('UIUX')"></button>
            <button class="blaze-next arrow arrow-right" @click="next('UIUX')"></button>
          </div>
        </div>
      </div>

      <div id="frontend" v-show="currentSection === 'Frontend'" class="section sticky-section">
        <div class="blaze-slider" ref="frontendSlider">
          <div class="blaze-container slider-container">
            <div class="slider-title">Frontend Projects</div>
            <div class="blaze-track">
              <div v-for="project in frontendProjects" :key="project.id" :class="[
                'blaze-slide',
                'card',
                {
                  'fade-in': !loading,
                  clicked: selectedProjectId === project.id,
                },
                { 'fade-in': isDetailsVisible },
              ]" @click="showDetails(project.id, 'Frontend')">
                <img :src="getImagePath(project.id)" :alt="project.name" />
                <div class="card-content">
                  <h3>{{ project.title }}</h3>
                  <div class="card-tags">
                    <div class="tag">{{ project.author }}</div>
                    <div class="tag">{{ project.date }}</div>
                  </div>
                </div>
                <div class="see-more"></div>
              </div>
            </div>
            <!--  <div class="fade-left"></div>
            <div class="fade-right"></div> -->
            <button class="blaze-prev arrow arrow-left" @click="prev('Frontend')"></button>
            <button class="blaze-next arrow arrow-right" @click="next('Frontend')"></button>
          </div>
        </div>
      </div>

      <div id="visual" v-show="currentSection === 'Visual'" class="section sticky-section">
        <div class="blaze-slider" ref="visualSlider">
          <div class="blaze-container slider-container">
            <div class="slider-title">Visual Projects</div>
            <div class="blaze-track">
              <div v-for="project in visualProjects" :key="project.id" :class="[
                'blaze-slide',
                'card',
                {
                  'fade-in': !loading,
                  clicked: selectedProjectId === project.id,
                },
              ]" @click="showDetails(project.id, 'Visual')">
                <img :src="getImagePath(project.id)" :alt="project.name" />
                <div class="card-content">
                  <h3>{{ project.title }}</h3>
                  <div class="card-tags">
                    <div class="tag">{{ project.author }}</div>
                    <div class="tag">{{ project.date }}</div>
                  </div>
                </div>
                <div class="see-more"></div>
              </div>
            </div>
            <!--  <div class="fade-left"></div>
            <div class="fade-right"></div> -->
            <button class="blaze-prev arrow arrow-left" @click="prev('Visual')"></button>
            <button class="blaze-next arrow arrow-right" @click="next('Visual')"></button>
          </div>
        </div>
      </div>

      <!-- Project Details Section -->
      <div id="projectDetails" class="details section">
        <div class="details-content fade-in">
          <div class="details-project">
            <div class="details-summary">
              <div class="details-header">
                <h2>{{ projectDetails.title }} Overview</h2>
                <div>{{ projectDetails.description }}</div>
                <div class="card-tags">
                  <div class="tag">{{ projectDetails.author }}</div>
                  <div class="tag">{{ projectDetails.date }}</div>
                </div>
              </div>
              <div class="blaze-slider" ref="projectSlider">
                <div class="blaze-container slider-container">
                  <div class="blaze-track">
                    <div v-for="(imagePath, index) in imagePaths" :key="index" class="blaze-slide">
                      <img :src="imagePath" :alt="'Project ' +
                        projectDetails.id +
                        ' Image ' +
                        (index + 1)
                        " />
                    </div>
                  </div>
                  <div class="controls">
                    <button class="blaze-prev">prev</button>
                    <div class="blaze-pagination"></div>
                    <button class="blaze-next">next</button>
                  </div>
                  <!-- Pagination container -->
                </div>
              </div>
            </div>
            <div class="details-process">
              <h3>Project Details</h3>
              <div class="details-final-design">
                <div class="label">Project Overview</div>
                <br />{{ projectDetails.projectOverview }}
              </div>
              <div class="details-results-impact">
                <div class="label">Problem Statement</div>
                <br />{{ projectDetails.problemStatement }}
              </div>
              <div class="details-final-design">
                <div class="label">Solution</div>
                <br />{{ projectDetails.solution }}
              </div>
              <div class="details-results-impact">
                <div class="label">Results and Impact</div>
                <br />{{ projectDetails.resultsImpact }}
              </div>
            </div>
            <div class="details-skills">
              <h3>Skills & Tools</h3>
              <ul>
                <li v-for="skill in projectDetails.skills" :key="skill">
                  <p v-html="formatSkill(skill)"></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- About Section -->
      <div id="about" class="about-section">
        <div class="about-content">
          <h2>About Me</h2>
          <p>
            I am Chidanand Hiremath, a passionate product designer who creates
            human-centered, well-researched, responsible, and sustainable
            goodness. I believe in art that resonates with users and makes a
            positive impact.
          </p>
        </div>
        <div class="about-photo">
          <img src="./assets/chidanand-hiremath.jpg" alt="About Me" class="circular-photo" />
        </div>
      </div>

      <!-- Testimonials Section -->
      <div class="testimonials-section">
        <div class="blaze-slider" ref="testimonialsSlider">
          <div class="blaze-container slider-container">
            <div class="blaze-track">
              <div v-for="testimonial in testimonials" :key="testimonial.id" class="blaze-slide testimonial-card">
                <p class="testimonial-text">{{ testimonial.text }}</p>
                <div class="testimonial-info">
                  <img :src="getTestimonialImagePath(testimonial.id)" alt="Client Photo" class="circular-photo" />
                  <div>
                    <p class="testimonial-name">{{ testimonial.name }}</p>
                    <p class="testimonial-position">
                      {{ testimonial.position }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button class="blaze-prev arrow arrow-left"></button>
            <button class="blaze-next arrow arrow-right"></button>
          </div>
        </div>
      </div>

      <!-- Contact Section -->
      <div id="contact" class="contact-section">
        <h2>Chidanand Hiremath's links</h2>
        <div class="contact-links">
          <a href="https://www.linkedin.com/in/chidanandhiremath" target="_blank" class="contact-link">
            <img src="./assets/linkedin.svg" alt="Chidanand's Linkedin" class="contact-icon" />
            LinkedIn
          </a>
          <a href="https://twitter.com/yourprofile" target="_blank" class="contact-link">
            <img src="./assets/behance.svg" alt="Chidanand's Behance" class="contact-icon" />
            Behance
          </a>
          <a href="https://dribbble.com/chivoyage" target="_blank" class="contact-link">
            <img src="./assets/dribbble.svg" alt="Chidanand's Dribbble" class="contact-icon" />
            Dribbble
          </a>
          <a href="https://youtube.com/chivoyage" class="contact-link">
            <img src="./assets/youtube.svg" alt="Chidanand's Youtube" class="contact-icon" />
            Youtube
          </a>
          <a href="https://youtube.com/chivoyage" class="contact-link">
            <img src="./assets/email.svg" alt="Email" class="contact-icon" />
            Email
          </a>
          <!-- Add more links as needed -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlazeSlider from "blaze-slider";
import "blaze-slider/dist/blaze.css";

export default {
  data() {
    return {
      uiuxProjects: [],
      frontendProjects: [],
      visualProjects: [],
      testimonials: [],
      imagePaths: [],
      uiuxSliderContent: "",
      frontendSliderContent: "",
      visualSliderContent: "",
      projectSliderContent: "",
      projectDetails: "",
      loading: true, // Add loading state
      selectedProjectId: null, // Track the selected project
      currentSection: "UIUX", // Track the current section
      sliderInstances: {}, // Store slider instances
    };
  },

  mounted() {
    this.loadProjects();
    this.loadTestimonials();

    this.$nextTick(() => {
      const uxuiSlider = this.$refs.uiuxSlider;
      const frontendSlider = this.$refs.frontendSlider;
      const visualSlider = this.$refs.visualSlider;

      // Add event listeners for UX/UI slider
      if (uxuiSlider) {
        uxuiSlider.addEventListener("click", (event) => {
          const cardElement = event.target.closest(".card");
          if (cardElement && cardElement.dataset.id) {
            const projectId = cardElement.dataset.id;
            this.showDetails(projectId, "UIUX");
          } else {
            console.warn(
              "Clicked outside of a card or card element has no dataset"
            );
          }
        });
      }

      // Add event listeners for Frontend slider
      if (frontendSlider) {
        frontendSlider.addEventListener("click", (event) => {
          const cardElement = event.target.closest(".card");
          if (cardElement && cardElement.dataset.id) {
            const projectId = cardElement.dataset.id;
            this.showDetails(projectId, "Frontend");
          } else {
            console.warn(
              "Clicked outside of a card or card element has no dataset"
            );
          }
        });
      }

      // Add event listeners for Visual slider
      if (visualSlider) {
        visualSlider.addEventListener("click", (event) => {
          const cardElement = event.target.closest(".card");
          if (cardElement && cardElement.dataset.id) {
            const projectId = cardElement.dataset.id;
            this.showDetails(projectId, "Visual");
          } else {
            console.warn(
              "Clicked outside of a card or card element has no dataset"
            );
          }
        });
      }
    });
  },
  watch: {
    loading(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.initializeBlazeSlider();
          this.showDetails(this.uiuxProjects[0].id, "UIUX"); // Show the first project by default
        });
      }
    },
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
    scrollToHero() {
      this.scrollToSection("hero");
    },

    async loadTestimonials() {
      const response = await fetch("/testimonials.json");
      if (!response.ok) {
        console.error(
          "Failed to fetch testimonials.json:",
          response.statusText
        );
        return;
      }
      const data = await response.json();
      this.testimonials = data;

      // Initialize testimonials slider
      this.$nextTick(() => {
        const testimonialsSlider = this.$refs.testimonialsSlider;
        if (testimonialsSlider) {
          new BlazeSlider(testimonialsSlider, {
            all: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
              slideGap: "16px",
              loop: true,
              enableAutoplay: true,
              autoplaySpeed: 3000,
            },
            "(min-width: 768px)": {
              slidesToShow: 2.5,
              slidesToScroll: 1,
              slideGap: "30px",
            },
            "(min-width: 1025px)": {
              slidesToShow: 3,
              slidesToScroll: 1,
              slideGap: "40px",
            },
            "(min-width: 1280px)": {
              slidesToShow: 4,
              slidesToScroll: 1,
              slideGap: "40px",
            },
            "(min-width: 1400px)": {
              slidesToShow: 5,
              slidesToScroll: 1,
              slideGap: "50px",
            },
          });
        } else {
          console.error(`Slider element not found for testimonialsSlider`);
        }
      });
    },

    getTestimonialImagePath(id) {
      return require(`./assets/testimonials/client${id}.jpg`);
    },

    async loadProjects() {
      const response = await fetch("/projects.json");
      if (!response.ok) {
        console.error("Failed to fetch projects.json:", response.statusText);
        return;
      }
      const data = await response.json();
      this.uiuxProjects = data.uiuxProjects;
      this.frontendProjects = data.frontendProjects;
      this.visualProjects = data.visualProjects;

      this.uiuxSliderContent = this.uiuxProjects
        .map(
          (project) => `
      <div class="blaze-slide card" id="card-${project.id}">
        <img src="${this.getImagePath(project.id)}" alt="${project.name}" />
        <div class="card-content">
          <h2>${project.title}</h2>
          <div class="card-tags">
            <p>${project.author}</p>
            <p>${project.date}</p>
          </div>
        </div>
      </div>
    `
        )
        .join("");

      this.frontendSliderContent = this.frontendProjects
        .map(
          (project) => `
      <div class="blaze-slide card" id="card-${project.id}">
        <img src="${this.getImagePath(project.id)}" alt="${project.name}" />
        <div class="card-content">
          <h2>${project.title}</h2>
          <div class="card-tags">
            <p>${project.author}</p>
            <p>${project.date}</p>
          </div>
        </div>
      </div>
    `
        )
        .join("");

      this.visualSliderContent = this.visualProjects
        .map(
          (project) => `
      <div class="blaze-slide card" id="card-${project.id}">
        <img src="${this.getImagePath(project.id)}" alt="${project.name}" />
        <div class="card-content">
          <h2>${project.title}</h2>
          <div class="card-tags">
            <p>${project.author}</p>
            <p>${project.date}</p>
          </div>
        </div>
      </div>
    `
        )
        .join("");

      this.loading = false; // Hide loading screen

      // Initialize sliders after loading projects
      this.$nextTick(() => {
        this.initializeBlazeSlider();
      });
    },

    getImagePath(id) {
      return require(`./assets/projects/Project${id}/project${id}.jpg`);
    },

    initializeBlazeSlider() {
      const sections = ["UIUX", "Frontend", "Visual"];
      sections.forEach((section) => {
        const sliderRef = `${section.toLowerCase()}Slider`;
        if (this.$refs[sliderRef]) {
          const sliderInstance = new BlazeSlider(this.$refs[sliderRef], {
            all: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
              slideGap: "16px",
              loop: true,
              enableAutoplay: false,
              autoplaySpeed: 3000,
            },
            "(min-width: 520px)": {
              slidesToShow: 3.5,
              slidesToScroll: 1,
              slideGap: "24px",
            },
            "(min-width: 768px)": {
              slidesToShow: 3.5,
              slidesToScroll: 1,
              slideGap: "30px",
            },
            "(min-width: 1024px)": {
              slidesToShow: 3,
              slidesToScroll: 1,
              slideGap: "40px",
            },
            "(min-width: 1280px)": {
              slidesToShow: 4,
              slidesToScroll: 1,
              slideGap: "50px",
            },
            "(min-width: 1440px)": {
              slidesToShow: 5,
              slidesToScroll: 1,
              slideGap: "50px",
            },
          });
          this.sliderInstances[section] = sliderInstance;
        } else {
          console.error(`Slider element not found for ${section}`);
        }
      });
    },

    formatSkill(skill) {
      return skill.replace(/\*\*(.*?)\*\*/, "<b>$1</b>").replace(/\n/g, "<br>");
    },

    showDetails(projectId, section) {
      this.selectedProjectId = projectId;
      const projects = this[`${section.toLowerCase()}Projects`];
      if (!projects || projects.length === 0) {
        console.error(`No projects found for section: ${section}`);
        return;
      }
      const project = projects.find((p) => p.id === projectId);
      if (!project) {
        console.error(
          `Project with ID ${projectId} not found in section: ${section}`
        );
        return;
      }

      // Generate image paths for the project
      this.imagePaths = [];
      for (let i = 0; i < 10; i++) {
        try {
          const imagePath = require(`./assets/projects/Project${project.id
            }/project${project.id}${i === 0 ? "" : String.fromCharCode(96 + i)
            }.jpg`);
          this.imagePaths.push(imagePath);
        } catch (error) {
          console.warn(
            `Image not found: project${project.id}${i === 0 ? "" : String.fromCharCode(96 + i)
            }.jpg`
          );
          break;
        }
      }

      // Set project details
      this.projectDetails = {
        ...project,
        resultsImpact: project.resultsImpact || project.solution,
      };



      // Initialize the slider after DOM update
      this.$nextTick(() => {
        const projectSlider = this.$refs.projectSlider;
        if (projectSlider) {
          const config = {
            all: {
              slidesToShow: 1,
              slidesToScroll: 1,
              slideGap: "16px",
              loop: true,
              enableAutoplay: false,
              autoplaySpeed: 3000,
              pagination: {
                el: ".blaze-pagination",
                clickable: true,
              },
            },
          };

          if (this.sliderInstances["projectSlider"]) {
            this.sliderInstances["projectSlider"].destroy();
          }

          const sliderInstance = new BlazeSlider(projectSlider, config);
          this.sliderInstances["projectSlider"] = sliderInstance;

          // Replace pagination buttons with thumbnails
          this.updatePaginationThumbnails();

          // Handle window resize to update thumbnails
          window.addEventListener(
            "resize",
            this.debounce(this.updatePaginationThumbnails, 200)
          );
        } else {
          console.error(`Slider element not found for projectSlider`);
        }
      });


      // Apply fade-in effect 
      this.isDetailsVisible = false; this.$nextTick(() => {
        this.isDetailsVisible = true;
        const projectDetailsElement = this.$refs.projectDetails;
        if (projectDetailsElement) {
          projectDetailsElement.classList.remove('fade-in');
          void projectDetailsElement.offsetWidth;
          // Trigger reflow 
          projectDetailsElement.classList.add('fade-in');
        }
      });


    },

    updatePaginationThumbnails() {
      // Replace pagination buttons with thumbnails
      this.$nextTick(() => {
        const paginationContainer = document.querySelector(".blaze-pagination");
        if (paginationContainer) {
          const paginationButtons = Array.from(
            paginationContainer.querySelectorAll("button")
          );
          const numButtons = paginationButtons.length;
          const numThumbnails = this.imagePaths.length;

          // Update existing buttons
          paginationButtons.forEach((button, index) => {
            if (index < numThumbnails) {
              button.style.backgroundImage = `url("${this.imagePaths[index]}")`;
            }
          });

          // Remove extra buttons if any
          for (let i = numThumbnails; i < numButtons; i++) {
            paginationButtons[i].remove();
          }

          // Add missing buttons if any
          for (let i = numButtons; i < numThumbnails; i++) {
            const newButton = document.createElement("button");
            newButton.style.backgroundImage = `url("${this.imagePaths[i]}")`;
            newButton.addEventListener("click", (event) => {
              const target = event.target.closest("button");
              if (target && target.dataset) {
                // Your existing logic using target.dataset
                console.log(`Clicked thumbnail ${target.dataset.index}`);
              } else {
                console.warn(
                  "Clicked outside of buttons or button has no dataset"
                );
              }
            });
            paginationContainer.appendChild(newButton);
          }
        }
      });
    },

    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const later = () => {
          clearTimeout(timeout);
          func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },

    navigate(page) {
      this.currentSection = page;
      document.body.classList.add("page-transition");
      setTimeout(() => {
        document.body.classList.remove("page-transition");
      }, 1000);

      // Highlight the first project of the selected section
      const projects = this[`${page.toLowerCase()}Projects`];
      if (projects && projects.length > 0) {
        this.showDetails(projects[0].id, page);
      }
    },

    shouldShowArrows(section) {
      const projects = this[`${section.toLowerCase()}Projects`];
      const slidesToShow = this.getSlidesToShow();
      return projects.length > slidesToShow;
    },
    getSlidesToShow() {
      const width = window.innerWidth;
      if (width >= 1440) return 5;
      if (width >= 1280) return 4;
      if (width >= 1025) return 3;
      if (width >= 768) return 2.5;
      return 1.5;
    },

    prev(section) {
      if (this.sliderInstances[section]) {
        this.sliderInstances[section].prev();
      } else {
        console.error(`Slider instance not found for ${section}`);
      }
    },
    next(section) {
      if (this.sliderInstances[section]) {
        this.sliderInstances[section].next();
      } else {
        console.error(`Slider instance not found for ${section}`);
      }
    },
  },
};


/*
document.addEventListener('DOMContentLoaded', function() {
  const stickySections = document.querySelectorAll('#uiux, #frontend, #visual');
  const projectDetails = document.querySelector('#projectDetails');

  const observer = new IntersectionObserver(
    ([entry]) => {
      stickySections.forEach(section => {
        if (entry.isIntersecting) {
          section.classList.add('sticky-active');
        } else {
          section.classList.remove('sticky-active');
        }
      });
    },
    { rootMargin: `0px 0px -100% 0px`}
  );

  observer.observe(projectDetails);
});
*/

</script>

<style scoped>
@import "./assets/stil.css";

.blaze-slider {
  --slides-to-show: 1;
  --slide-gap: 20px;
  direction: ltr;
}

.blaze-container {
  position: relative;
}

.blaze-track-container {
  overflow: hidden;
}

.blaze-track {
  will-change: transform;
  touch-action: pan-y;
  display: flex;
  gap: var(--slide-gap);
  --slide-width: calc((100% - (var(--slides-to-show) - 1) * var(--slide-gap)) / var(--slides-to-show));
  box-sizing: border-box;
}

.blaze-track>* {
  box-sizing: border-box;
  width: var(--slide-width);
  flex-shrink: 0;
}

body.page-transition {
  animation: circle-reveal 1s forwards;
}

@keyframes circle-reveal {
  0% {
    clip-path: circle(0% at 50% 50%);
  }

  100% {
    clip-path: circle(150% at 50% 50%);
  }
}

.uiux-background {
  background-color: #f0f8ff;
}

.frontend-background {
  background-color: #ffe4e1;
}

.visual-background {
  background-color: #e6e6fa;
}

/* UI/UX specific styles */
.uiux .card {
  font-family: Inter;
}

.uiux .details-project {
  font-family: Inter;
}

/* Frontend specific styles */
.frontend .card {
  font-family: monospace;
}

.frontend .details-project {
  font-family: monospace;
}

/* Visual specific styles */
.visual .card {
  font-family: serif;
}

.visual .details-project {
  font-family: serif;
}




</style>
